import React from "react";
import { Helmet } from "react-helmet";
import Layout from '../components/Layout';
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/SEO";
import Theme, { theme } from "@pagerland/themes/src/RealEstate";
import Container from "@pagerland/common/src/components/Container";
import preview from "@pagerland/themes/src/RealEstate/assets/backgroundcourses-1.jpg";

import {
  Skus, CartOverview
} from "@pagerland/themes/src/RealEstate/containers";

import PropTypes from "prop-types";

import { CartProvider } from "react-use-cart";

export default function Shop({ url, pageContext, ContainerProps }) {

  const productsData = useStaticQuery(graphql`
    query products {
      allStripePrice(
        filter: { active: { eq: true } },
        sort: { fields: [unit_amount] }
      ) {
        edges {
          node {
            id
            active
            currency
            unit_amount
            product {
              id
              name
              description
              images
            }
          }
        }
      }
    }
  `)

  return (
    <Theme>
      <Helmet>
        <link href={theme.typography.googleFont} rel="stylesheet" />
        <meta name="theme-color" content={theme.colors.primary} />
        <meta property="og:image" content={`${url}${preview}`} />
      </Helmet>
      <SEO title={"Montessori and Children Toys "} />
      <Layout>
        <Container  {...ContainerProps}>
          <CartProvider>
            <CartOverview />
            <Skus productsData={productsData} category={pageContext?.category} />
          </CartProvider>
        </Container>
      </Layout>
    </Theme>
  )
}

Shop.propTypes = {
  url: PropTypes.string,
  pageContext: PropTypes.shape({
    category: PropTypes.string,
  }),
};

Shop.defaultProps = {
  url: "https://www.instagram.com/practicalmontessori/",
};